export const ARCADE_CABINET_ASPECT_RATIO = 0.7617260787992496;
export const ARCADE_CABINET_WIDTH = 300;
export const ARCADE_CABINET_HEIGHT = ARCADE_CABINET_WIDTH / ARCADE_CABINET_ASPECT_RATIO;

export const SIDE_PANEL_WIDTH_RATIO = 0.130541872;
export const SIDE_PANEL_WIDTH = ARCADE_CABINET_WIDTH * SIDE_PANEL_WIDTH_RATIO;
export const TOP_PANEL_HEIGHT_RATIO = 0.124765478;

export const BUTTON_WIDTH = 38;
export const BUTTON_HEIGHT = 23;

export const SMALL_CABINET_HEIGHT = 184;
